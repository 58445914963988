import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { Box, Text } from '../../shared/components';
import { useSegment } from '../providers/segment/use-segment';
import { Segment } from '../providers/segment/Segment';
import { Block } from './Block';
import { Droppable } from '../utility/Droppable';
import { useDroppable } from '../utility/useDroppable';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { DROPPABLE_BLOCK_TYPES } from './block-helpers';
import { Conditional } from '../utility/Conditional';
import { colors } from '../../shared/styles';
import { useEvent } from '../../shared/use-event';
import { shadows } from '../../shared/shared-styles';
import { useContent } from '../providers/content/use-content';
import { Content } from '../providers/content/Content';
import * as uuid from 'uuid';

const invalidBlocksDrop = ['benefits', 'pricing', 'network', 'pageBreak'];

export const Column = memo(({ stack }) => {
  const columnRef = useRef();
  const segment = useSegment();
  const toolkit = useToolKit();
  const drag = ToolKit.getDrag(toolkit);

  const index = Segment.getIndex(segment, 'column');
  const parent = Segment.getParent(segment);
  const parentChildCount = Segment.countParentsChildren(segment);
  const properties = Segment.getProperties(segment);

  const width = properties?.width || 1;
  const widthValue = width * 100;

  const getDropStatus = (drag) => {
    const isBlockTypeValid = !invalidBlocksDrop?.includes(drag?.toolType);
    return drag?.isDragging && DROPPABLE_BLOCK_TYPES.includes(drag?.type) && isBlockTypeValid && parent?.childCount < 3;
  };

  const isDropEnabled = getDropStatus(drag);
  const droppable = useDroppable({
    disabled: !isDropEnabled,
    activeRects: {
      left: [-1, 10, 15, 90],
      right: [85, 10, 101, 90],
    },
  });

  const hasResizeHandle = parentChildCount === 2 && index < parentChildCount - 1;

  const disableDragNDrop = () => ToolKit.disableDrag(toolkit);

  const defaultArrangement = !properties?.arrangeBlocks || properties?.arrangeBlocks === 'stretch-evenly';

  return (
    <Box
      css={`
        position: relative;
        width: ${stack ? '100' : widthValue}%;
      `}
      key={segment?.id}
      className="section-styles"
      ref={columnRef}
      data-scope="column"
      {...droppable.attributes}
    >
      <Box
        flex="column space-between"
        css={`
          width: 100%;
          height: 100%;
          .arrange-block {
            transition: max-height 0.5s ease;
            ${defaultArrangement
              ? `height: 100%; max-height: 100%;`
              : `
              max-height: auto;
              :first-of-type {
                flex-grow: 1;
              }
              :last-of-type {
                flex-grow: 1;
              }
            `}
          }
        `}
      >
        {segment?.data?.children?.map((child, index) => (
          <Segment key={child?.id} scope="column" index={index} element={columnRef} {...child}>
            <Block />
          </Segment>
        ))}
      </Box>

      <Droppable {...droppable} />
      <Conditional if={hasResizeHandle && !drag?.isDragging && !ToolKit.isStatus(toolkit, ToolKit.STATUS.READ_ONLY)}>
        <Resizer columnRef={columnRef} disableDragNDrop={disableDragNDrop} />
      </Conditional>
    </Box>
  );
});

const Resizer = ({ columnRef, disableDragNDrop }) => {
  const toolkit = useToolKit();
  const [resizing, setResizing] = useState(false);

  const onMouseDown = () => setResizing(true);

  useEvent('mouseup', () => {
    if (resizing) {
      ToolKit.resetDrag(toolkit);
    }
    setResizing(false);
  });
  const onMouseEnter = () => disableDragNDrop(true);

  const onMouseLeave = () => {
    if (!resizing) {
      ToolKit.resetDrag(toolkit);
    }
  };

  useEffect(() => {
    document.body.style.cursor = resizing ? 'ew-resize' : '';
  }, [resizing]);

  return (
    <Box
      css={`
        position: absolute;
        top: 0;
        right: -3px;
        bottom: 0;
        width: 7px;
        background-color: ${colors.purple};
        ${shadows.lg}
        cursor: ew-resize;
        border-radius: 30px;
        z-index: 100000;
        ${resizing
          ? 'opacity: 1'
          : `
          opacity: 0;
            :hover {
              opacity: 1;
            } 
          `};
        transition: opacity 0.3s ease;
      `}
      draggable={true}
      onMouseDown={onMouseDown}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Conditional if={resizing}>
        <ResizeUpdater columnElement={columnRef?.current} />
      </Conditional>
    </Box>
  );
};

const oneThird = 1 / 3;

const roundToNearestFive = (num) => {
  const steps = [0.3, 0.35, 0.4, 0.45, 0.5, 0.55, 0.6, 0.65, 0.7];
  const closest = steps.reduce((prev, curr) => (Math.abs(curr - num) < Math.abs(prev - num) ? curr : prev));
  return closest;
};

const ResizeUpdater = ({ columnElement }) => {
  const sessionId = useRef(uuid.v4());
  const { content, local } = useContent({ ignoreSelection: true });
  const segment = useSegment();

  const rowElement = Segment.getElement(segment, 'row');

  const [render, setRender] = useState();

  const { children, index } = useMemo(() => {
    const parent = Content.getParent(content, segment?.id);
    const children = Content.getChildren(content, parent?.children);
    const index = children?.findIndex((child) => child?.id === segment?.id);
    return {
      parent,
      children,
      index,
    };
  }, [segment?.id, content, render]);

  const { position, currentWidths, maxWidth, percentWidths, rawWidths } = useMemo(() => {
    const bounds = rowElement?.getBoundingClientRect();
    const maxWidth = bounds?.width;
    const children = rowElement?.querySelectorAll('[data-scope="column"]');
    const rawWidths = Array.from(children).map((child) => child?.getBoundingClientRect().width);
    const percentWidths = rawWidths.map((width) => width / maxWidth);
    const columnBounds = columnElement?.getBoundingClientRect();
    return {
      maxWidth,
      percentWidths,
      rawWidths,
      position: columnBounds?.right,
      currentWidths: [
        `${(percentWidths?.[index] * 100).toFixed(0)}%`,
        `${(percentWidths?.[index + 1] * 100).toFixed(0)}%`,
      ],
    };
  }, [columnElement, rowElement, content, render]);

  const updateWidths = (nearestIncrement) => {
    let updatedWidths = [...percentWidths];

    updatedWidths[index] = nearestIncrement;
    updatedWidths[index + 1] = 1 - nearestIncrement;

    // Generate updates for all columns
    const updates = updatedWidths.map((width, i) => ({
      id: children?.[i]?.id,
      updateFn: (prev) => ({
        ...prev,
        properties: { ...prev.properties, width },
      }),
    }));

    const setter = local.createBatch(sessionId.current);
    Content.bulkUpdate(setter, updates);
    setTimeout(() => {
      setRender(Date.now());
    }, 10);
  };

  useEffect(() => {
    return () => {
      local.sendBatch();
    };
  }, []);

  useEvent('mousemove', (event) => {
    const difference = position - event.clientX;
    const width = rawWidths?.[index] - difference;
    const percentWidth = width / maxWidth;
    const nearestIncrement = roundToNearestFive(percentWidth);
    if (percentWidths?.[index] !== nearestIncrement) {
      updateWidths(nearestIncrement);
    }
  });

  return (
    <Box
      css={`
        position: absolute;
        left: calc(50% - 40px);
        width: 80px;
        top: calc(100% + 8px);
        height: 32px;
        border-radius: 8px;
        background-color: ${colors.purple};
        ${shadows.lg}
        z-index: 1000;
        pointer-events: none;
        p {
          color: white;
        }
      `}
      flex="center"
      key={render}
    >
      <Text caption>{currentWidths.join(' / ')}</Text>
    </Box>
  );
};

import { DEFAULT_COMPOSITION_OPTIONS } from '../content/composition/composition-utils';

export const initialToolkit = {
  users: {},
  viewMode: 'desktop',
  settings: false,
  sectionsMenu: null,
  metadataByBlock: {},
  scrollIntoView: {
    id: '',
    updatedAt: null,
  },
  selection: {
    at: 0,
    multiSelect: {},
    range: {
      anchor: '',
      focus: '',
    },
    scope: '',
    type: '',
  },
  drag: {
    isComplete: false,
    isDragging: false,
    isAvailable: true,
    type: '',
    edge: '',
    sourceId: '',
    destinationId: '',
    toolType: '',
    copiedData: null,
    completedAt: Date.now(),
    compositionOptions: DEFAULT_COMPOSITION_OPTIONS,
  },
  keyboard: {
    updatedAt: '',
    shortkey: '',
  },
  contextMenu: { display: false, position: { x: 0, y: 0 } },
  editorType: 'page',
  modal: {
    type: '',
    data: {},
  },
  comment: {
    highlightId: '', // Optionally highlight a specific comment (shareable links)
    source: '',
    blockId: '',
    data: {},
  },
  commentsByComponent: {},
  versionChanges: {},
  isVersionChangesLoading: false,
  currentTab: {
    type: 'tools',
    options: {
      emphasizeAt: null,
    },
  },
};

export const toolKitReducer = (state, action) => {
  switch (action?.type) {
    case 'SET_PROPERTIES': {
      return {
        ...state,
        ...action.payload,
      };
    }
    case 'UPDATE_PROPERTY': {
      return {
        ...state,
        [action?.property]: {
          ...(state?.[action?.property] || {}),
          ...action.payload,
        },
      };
    }
    case 'SET_PROPERTY': {
      return {
        ...state,
        [action?.property]: action.payload,
      };
    }
    case 'RESET_PROPERTY': {
      return {
        ...state,
        [action?.property]: initialToolkit[action?.property],
      };
    }
    default: {
      return state;
    }
  }
};

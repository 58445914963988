import { ChatsCircle, ClockClockwise, Plugs, PlugsConnected, Wrench } from '@phosphor-icons/react';
import { Badge, Box, Dot, Text } from '../../shared/components';
import { colors } from '../../shared/styles';
import { useDebounceValue } from '../../shared/use-debounce-value';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { propertiesMap, PropertiesPanel } from './PropertiesPanel';
import { Tools } from './Tools';
import { animation, container } from '../../shared/shared-styles';
import { Tooltip } from '../../common/components/Tooltip';
import { Connections } from './Connections';
import { Comments } from './Comments/Comments';
import { useEffect, useMemo, useState } from 'react';
import { SIDEBAR_TABS } from '../utility/constants';
import { useEditorResource } from '../../BriteEditor/use-editor-resource';
import { useSearchParams } from '../../shared/use-search-params';
import { Conditional } from '../utility/Conditional';
import { VersionHistory } from './VersionHistory';

const tabWidth = {
  '': '44px',
  [SIDEBAR_TABS.TOOLS]: '280px',
  [SIDEBAR_TABS.COMMENTS]: '350px',
  [SIDEBAR_TABS.CONNECTIONS]: '280px',
  [SIDEBAR_TABS.VERSION_HISTORY]: '300px',
};

export const Sidebar = () => {
  const toolkit = useToolKit();

  const selection = ToolKit.getSelection(toolkit);
  const { type: tab, options = {} } = ToolKit.getCurrentTab(toolkit);
  const isDisplaySettings = ToolKit.getIsDisplaySettings(toolkit);
  const canDisplayPropertiesPanel = selection?.type in propertiesMap && isDisplaySettings;
  const [delayedDisplaySettings] = useDebounceValue(canDisplayPropertiesPanel, 300);
  const comment = ToolKit.getComment(toolkit);

  const toggleTab = (type) => {
    if (tab === type) {
      ToolKit.setCurrentTab(toolkit, '');
    } else {
      ToolKit.setCurrentTab(toolkit, type);
    }
  };

  useEffect(() => {
    if (!tab && comment?.source) {
      ToolKit.setCurrentTab(toolkit, SIDEBAR_TABS.COMMENTS);
    }
  }, [comment]);

  return (
    <Box
      css={`
        position: relative;
        max-width: ${tabWidth[tab] || '0'};
        transition: max-width 0.2s ease;
        width: 100%;
        height: 100%;
        background-color: white;
        border-left: 1px solid ${colors.gray[300]};
        padding-left: 1px;
        margin-left: 1px;
      `}
      flex="right start"
      data-scope="sidebar"
    >
      {tab === SIDEBAR_TABS.TOOLS ? <Tools /> : null}
      {tab === SIDEBAR_TABS.COMMENTS ? <Comments /> : null}
      {tab === SIDEBAR_TABS.CONNECTIONS ? <Connections /> : null}
      {tab === SIDEBAR_TABS.VERSION_HISTORY ? <VersionHistory /> : null}
      <Tabs tab={tab} setTab={toggleTab} options={options} />
      {canDisplayPropertiesPanel || delayedDisplaySettings ? <PropertiesPanel /> : null}
    </Box>
  );
};

const tabStyle = (isSelected, emphasize) => `
  position: relative;
  width: 100%;
  height: 48px;
  gap: 8px;
  ${isSelected ? `background-color: ${colors.gray[100]};` : ''}
  ${container.hover}
  .selected {
    position: absolute;
    top: 8px;
    left: 0;
    border-radius: 0;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    width: 4px;
    height: 32px;
    transition: background-color 0.3s ease;
    background-color: ${isSelected ? colors.purple : 'transparent'};
  }
  .flip {
    transform: scaleX(-1);
  }
  svg {
    pointer-events: none;
    transition: color .3s ease;
    color: ${isSelected ? colors.purple : colors.gray[600]};
    ${emphasize && isSelected ? animation('bounce', '1s ease') : ''}
  }
`;

export const Tabs = ({ tab, setTab, options }) => {
  const [emphasize, setEmphasize] = useState(false);

  const { params } = useSearchParams();
  const comments = useEditorResource('comments');

  const toolkit = useToolKit();
  const { guide, pageIds } = useMemo(() => {
    const guide = ToolKit.getMetadata(toolkit, 'guide');
    return {
      pageIds: guide?.data?.Pages?.map((page) => page?.ID),
      guide,
    };
  });

  // Who doesn't?
  const needsConnection =
    !guide?.data?.ThemeID ||
    guide?.data?.ThemeID === '00000000-0000-0000-0000-000000000000' ||
    !guide?.data?.BenefitsPackageID ||
    guide?.data?.BenefitsPackageID === '00000000-0000-0000-0000-000000000000';

  const { countByPage, totalCount } = useMemo(() => {
    const list = comments?.data.reduce((acc, comment) => {
      const thread = comments?.data?.find((item) => item?.id === comment?.metadata?.threadId);
      if (!comment?.resolved && !thread?.resolved && pageIds?.includes(comment?.metadata?.pageId)) {
        return {
          ...acc,
          [comment?.metadata?.pageId]: [...(acc?.[comment?.metadata?.pageId] ?? []), comment],
        };
      }
      return acc;
    }, {});
    const totalCount = Object.values(list)?.reduce((prev, list) => prev + list?.length, 0);

    return {
      countByPage: list?.[params?.pageId]?.length || 0,
      totalCount,
    };
  }, [comments?.dataUpdatedAt, params?.pageId, pageIds?.length]);

  useEffect(() => {
    if (options?.emphasizeAt !== null) {
      setEmphasize(true);
      setTimeout(() => {
        setEmphasize(false);
      }, 1500);
    }
  }, [options?.emphasizeAt]);

  return (
    <Box
      css={`
        min-width: ${tabWidth['']};
        width: ${tabWidth['']};
        height: 100%;
        background-color: ${emphasize ? colors.gray[100] : 'white'};
        transition: background-color 0.4s ease;
      `}
    >
      <Conditional if={!ToolKit.isStatus(toolkit, ToolKit.STATUS.READ_ONLY)}>
        <Tooltip
          label={
            <span>
              Tools <strong>T</strong>
            </span>
          }
          shortkey
          popper={{ placement: 'left' }}
        >
          <Box
            onClick={() => setTab(SIDEBAR_TABS.TOOLS)}
            css={tabStyle(tab === SIDEBAR_TABS.TOOLS, emphasize)}
            flex="center"
          >
            <Box className="selected" />
            <Wrench size={24} color="currentColor" className="flip" />
          </Box>
        </Tooltip>
      </Conditional>

      <Tooltip
        label={
          <span>
            Comments <strong>C</strong>
          </span>
        }
        popper={{ placement: 'left' }}
      >
        <Box
          onClick={() => setTab(SIDEBAR_TABS.COMMENTS)}
          css={tabStyle(tab === SIDEBAR_TABS.COMMENTS, emphasize)}
          flex="center"
        >
          {tab === SIDEBAR_TABS.COMMENTS && countByPage > 0 ? (
            <Badge
              display={countByPage > 0}
              spacing={-6}
              css={`
                padding: 0;
                min-width: 16px;
                z-index: 1;
              `}
            >
              <Text
                css={`
                  color: white;
                `}
                helper
              >
                {countByPage}
              </Text>
            </Badge>
          ) : null}
          <Box className="selected" />
          <Dot
            display={(countByPage === 0 || tab !== SIDEBAR_TABS.COMMENTS) && totalCount > 0}
            spacing={-2}
            placement="top-right"
            color={`${colors.purple}DD`}
          >
            <ChatsCircle size={24} color="currentColor" />
          </Dot>
        </Box>
      </Tooltip>
      <Conditional if={!ToolKit.isStatus(toolkit, ToolKit.STATUS.READ_ONLY)}>
        <Tooltip
          label={
            <span>
              Connections <strong>N</strong>
            </span>
          }
          popper={{ placement: 'left' }}
        >
          <Box
            onClick={() => setTab(SIDEBAR_TABS.CONNECTIONS)}
            css={tabStyle(tab === SIDEBAR_TABS.CONNECTIONS, emphasize)}
            flex="center"
          >
            <Box className="selected" />
            <Dot display={needsConnection} spacing={-2} placement="top-right" color={`${colors.red[100]}DD`}>
              {tab === SIDEBAR_TABS.CONNECTIONS ? (
                <PlugsConnected size={24} color="currentColor" className="flip" />
              ) : (
                <Plugs size={24} color="currentColor" className="flip" />
              )}
            </Dot>
          </Box>
        </Tooltip>
      </Conditional>

      <Conditional if={!ToolKit.isStatus(toolkit, ToolKit.STATUS.READ_ONLY)}>
        <Tooltip
          label={
            <span>
              Version History <strong>V</strong>
            </span>
          }
          popper={{ placement: 'left' }}
        >
          <Box
            onClick={() => setTab(SIDEBAR_TABS.VERSION_HISTORY)}
            css={tabStyle(tab === SIDEBAR_TABS.VERSION_HISTORY, emphasize)}
            flex="center"
          >
            <Box className="selected" />
            <ClockClockwise size={24} color="currentColor" className="flip" />
          </Box>
        </Tooltip>
      </Conditional>
    </Box>
  );
};

import { usePopper } from 'react-popper';
import { Box, Button, Text } from '../../shared/components';
import { Portal } from '../../shared/components/Portal';
import { animation, shadows } from '../../shared/shared-styles';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { useEffect, useMemo, useState } from 'react';
import {
  AlignCenterVertical,
  ArrowsInLineVertical,
  ArrowsOutLineVertical,
  ChatCircle,
  Check,
  Clipboard,
  CodeBlock,
  Copy,
  CopySimple,
  Heart,
  Log,
  PencilSimple,
  Plus,
  Rows,
  Scissors,
  SplitVertical,
  SquareSplitVertical,
  Stack,
  Trash,
} from '@phosphor-icons/react';
import { Content } from '../providers/content/Content';
import { useContent } from '../providers/content/use-content';
import { Conditional } from './Conditional';
import { getCopyMultiSelectionActions, removeMultiSelection } from './multi-select-utils';
import { useStore } from '../../store-provider/use-store';
import { SIDEBAR_TABS } from './constants';
import { getThreadByBlock } from './editor-utils';
import { useEditorResource } from '../../BriteEditor/use-editor-resource';
import { colors } from '../../shared/styles';
import { getCopiableData, getPaste } from '../providers/content/content-utils';
import { usePropertyManager } from '../providers/property-manager/PropertyManager';
import { Editor, Range, Transforms } from 'slate';

export const ContextMenu = () => {
  const toolkit = useToolKit();
  const contextMenu = ToolKit.getContextMenu(toolkit);

  return (
    <Conditional if={contextMenu.display}>
      <ContextMenuPopper contextMenu={contextMenu} toolkit={toolkit} />
    </Conditional>
  );
};

const usePromise = (asyncFunctionFn, deps = []) => {
  const [data, setData] = useState();

  useEffect(() => {
    asyncFunctionFn().then(setData);
  }, [...deps]);
  return [data, setData];
};

export const ContextMenuPopper = ({ toolkit, contextMenu }) => {
  const {
    data: { devMode },
  } = useStore();

  const { properties } = usePropertyManager();

  const isSlateFocused = 'editor' in properties;

  const { content, sendUpdates } = useContent({ ignoreSelection: true });

  const [anchor, setAnchor] = useState();
  const [menu, setMenu] = useState();
  const popper = usePopper(anchor, menu, { placement: 'bottom-start' });

  const comments = useEditorResource('comments');
  const commentsByBlock = ToolKit.getCommentByBlockId(toolkit, contextMenu.context.id);
  const selection = ToolKit.getSelection(toolkit);
  const hasMultiSelection = ToolKit.hasMultiSelection(toolkit);

  const row = Content.getParent(content, contextMenu.context.id, 'row');
  const childCount = Content.getChildCount(content, contextMenu.context.id);
  const column = Content.getParent(content, contextMenu.context.id, 'column');

  const updateArrangement = (e) => {
    e.stopPropagation();
    Content.update(sendUpdates, column?.id, (data) => ({
      ...data,
      properties: {
        ...data.properties,
        arrangeBlocks: data?.properties?.arrangeBlocks === 'centered' ? 'stretch-evenly' : 'centered',
      },
    }));
  };

  const updateStackOnMobile = (e) => {
    e.stopPropagation();
    Content.update(sendUpdates, row?.id, (data) => ({
      ...data,
      properties: {
        ...data.properties,
        stackColumns: !data.properties.stackColumns,
      },
    }));
  };

  useEffect(() => {
    popper?.update?.();
  }, [contextMenu.updatedAt]);

  const comment = () => {
    ToolKit.setCurrentTab(toolkit, SIDEBAR_TABS.COMMENTS);
    if (commentsByBlock?.length) {
      const thread = getThreadByBlock(commentsByBlock, comments?.data);
      ToolKit.selectComment(toolkit, {
        source: 'block',
        blockId: thread?.metadata?.componentId,
        threadId: thread?.id,
      });
    } else {
      ToolKit.initComment(toolkit, contextMenu.context.id);
    }
  };

  const remove = () => {
    if (!hasMultiSelection) {
      Content.remove(sendUpdates, contextMenu.context.id);
    } else {
      removeMultiSelection(sendUpdates, selection);
      ToolKit.deselect(toolkit);
    }
  };

  const editSection = () => {
    ToolKit.select(toolkit, {
      scope: 'section',
      type: 'section',
      id: contextMenu.context.id,
    });
    ToolKit.setDisplaySettings(toolkit, true);
  };

  const [copiedData] = usePromise(async () => {
    let copy;
    try {
      copy = await navigator?.clipboard?.readText();
      const block = JSON.parse(copy);
      if (block?.metadata?.type === 'block' && block?.metadata?.editor === 'brite-editor-v2') {
        return block;
      } else {
        return {
          metadata: {
            type: 'block',
            editor: 'brite-editor-v2',
            selection: 'text',
          },
          data: copy,
        };
      }
    } catch {
      return {
        metadata: {
          type: 'block',
          editor: 'brite-editor-v2',
          selection: 'text',
        },
        data: copy,
      };
    }
  }, []);

  const copy = () => {
    const hasTextV2Selection =
      isSlateFocused && properties?.editor?.selection && !Range.isCollapsed(properties.editor.selection);
    if (!hasTextV2Selection) {
      const block = Content.get(content, contextMenu.context.id);
      const data = getCopiableData(block, {
        type: 'block',
        editor: 'brite-editor-v2',
        selection: 'single',
      });
      navigator.clipboard.writeText(data);
    } else {
      const selectedText = properties.editor.string(properties.editor.selection);
      navigator.clipboard.writeText(selectedText);
    }
  };

  const duplicate = () => {
    const block = Content.get(content, contextMenu.context.id);
    const data = getCopiableData(
      block,
      {
        type: 'block',
        editor: 'brite-editor-v2',
        selection: 'single',
      },
      false
    );
    const paste = getPaste(content, data, contextMenu.context.id);
    ToolKit.drop(toolkit, paste?.data);
  };

  const duplicateMultiSelect = () => {
    sendUpdates((old) => {
      const actions = getCopyMultiSelectionActions(old, selection);
      const content = Content.commit(old, actions);
      return { content, actions };
    });
  };

  const cut = () => {
    copy();
    remove();
  };

  const paste = () => {
    const paste = getPaste(content, copiedData, contextMenu.context.id);
    if (paste?.pasteType === 'single') {
      ToolKit.drop(toolkit, paste?.data);
    } else if (paste?.pasteType === 'text' && !!paste.data) {
      Transforms.insertText(properties.editor, paste.data);
    }
  };

  const mergeToSection = () => {
    Content.mergeToSection(sendUpdates, selection);
    ToolKit.deselect(toolkit);
  };

  const toggleSectionModal = (e) => {
    const section = Content.get(content, contextMenu.context.id);
    ToolKit.setModal(toolkit, {
      type: 'save-section',
      data: section,
    });
  };

  const hasGroupedSection = ToolKit.selectionHasGroupedSection(toolkit, content, contextMenu.context.id);
  const isSection = contextMenu.context?.toolType === 'section';
  const isBottomDragZone = contextMenu?.context?.toolType === 'bottom-drag-zone';
  const canPaste = copiedData?.metadata?.selection !== 'text' || isSlateFocused;
  const arrangeBlocks = column?.properties?.arrangeBlocks || 'stretch-evenly';
  const showStack = row?.type === 'row' && row?.children?.length > 1;
  const showArrange = column?.type === 'column' && row?.children?.length > 1 && column?.children?.length > 1;

  return (
    <>
      <Box
        css={`
          position: absolute;
          top: ${contextMenu.position.y}px;
          left: ${contextMenu.position.x}px;
        `}
        ref={setAnchor}
      />
      <Portal>
        <Box
          data-scope="context-menu"
          ref={setMenu}
          style={popper.styles.popper}
          {...popper.attributes.popper}
          css={`
            width: 240px;
            z-index: 1000;
          `}
          onClick={() => ToolKit.closeContextMenu(toolkit)}
        >
          <Box
            css={`
              transform-origin: top left;
              ${animation('grow', '.2s ease')}
              border-radius: 8px;
              border-top-left-radius: 0;
              background-color: white;
              ${shadows.lg}
              padding: 8px;
            `}
          >
            {!isBottomDragZone ? (
              <>
                {hasMultiSelection && hasGroupedSection ? (
                  <Box option onClick={mergeToSection}>
                    <Plus size={24} />
                    <Text label>Create Section</Text>
                  </Box>
                ) : !isSection && !hasMultiSelection ? (
                  <Box option onClick={comment}>
                    <ChatCircle size={24} />
                    <Text label>Comment</Text>
                  </Box>
                ) : null}
                {hasMultiSelection ? (
                  <Box option onClick={duplicateMultiSelect}>
                    <Copy size={24} />
                    <Text label>Duplicate</Text>
                  </Box>
                ) : null}
                {isSection && !hasMultiSelection && childCount > 0 ? (
                  <>
                    <Box option onClick={editSection}>
                      <PencilSimple size={24} />
                      <Text label>Edit</Text>
                    </Box>
                    <Box option onClick={toggleSectionModal}>
                      <Heart size={24} />
                      <Text label>Save Section</Text>
                    </Box>
                  </>
                ) : null}

                {!hasMultiSelection && !isSection ? (
                  <>
                    <Box option onClick={duplicate}>
                      <Copy size={24} />
                      <Text label>Duplicate</Text>
                    </Box>

                    <Box option onClick={copy} onMouseDown={(e) => e.preventDefault()}>
                      <CopySimple size={24} />
                      <Text label>Copy</Text>
                    </Box>
                    <Box option onClick={cut} disabled={isSlateFocused}>
                      <Scissors size={24} />
                      <Text label>Cut</Text>
                    </Box>
                  </>
                ) : null}
                {!hasMultiSelection ? (
                  <Box option onClick={paste} disabled={!canPaste}>
                    <Clipboard size={24} />
                    <Text label>Paste</Text>
                  </Box>
                ) : null}

                <Box
                  option
                  onClick={remove}
                  css={`
                    p,
                    svg {
                      color: red;
                    }
                  `}
                >
                  <Trash size={24} color="currentColor" />
                  <Text label>Remove</Text>
                </Box>

                {showStack || showArrange ? (
                  <Box
                    css={`
                      border-top: 1px solid ${colors.gray[300]};
                      width: 100%;
                      padding-top: 8px;
                      margin-top: 8px;
                    `}
                  />
                ) : null}

                {showStack ? (
                  <Box
                    option
                    onClick={updateStackOnMobile}
                    css={`
                      .check {
                        margin-left: auto;
                      }
                    `}
                  >
                    <Stack size={24} />
                    <Text label>Stack on Mobile</Text>
                    {row?.properties?.stackColumns ? <Check size={24} className="check" /> : null}
                  </Box>
                ) : null}
                {showArrange ? (
                  <Box
                    option
                    onClick={updateArrangement}
                    css={`
                      .check {
                        margin-left: auto;
                      }
                    `}
                  >
                    <AlignCenterVertical size={24} />
                    <Text label>Centered</Text>
                    {arrangeBlocks === 'centered' ? <Check size={24} className="check" /> : null}
                  </Box>
                ) : null}
              </>
            ) : null}

            {devMode ? (
              <Box
                css={`
                  margin-top: 8px;
                  padding-top: 8px;
                  border-top: 1px solid ${colors.lighterPurple};
                  p,
                  svg {
                    color: ${colors.purple};
                  }
                `}
              >
                <Box option onClick={() => console.log(content)}>
                  <Log size={24} color="currentColor" />
                  <Text label>Log Content</Text>
                </Box>
                <Box
                  option
                  onClick={() =>
                    ToolKit.setModal(toolkit, {
                      type: 'view-selection',
                      data: { id: contextMenu?.context?.id },
                    })
                  }
                >
                  <CodeBlock size={24} color="currentColor" />
                  <Text label>View Block</Text>
                </Box>
              </Box>
            ) : null}
          </Box>
        </Box>
      </Portal>
    </>
  );
};

import { addBlockToAdjacentRow } from './row-utils';

export const insertAction = (composition, action) => (composition[action.id] = { ...action, type: 'insert' });
export const updateAction = (composition, id, type, data = null) => {
  const currentType = composition[id]?.type;
  composition[id] = structuredClone({
    ...(composition[id] || {}),
    type: currentType === 'insert' ? 'insert' : type,
    data: {
      ...(composition?.[id]?.data || {}),
      ...data,
    },
    updates: { ...(composition?.[id]?.updates || {}), ...data },
  });
};
export const attachAction = (composition, action) => (composition[action.id] = action);

export const getAction = (composition, id) => {
  const content = composition._content || {};
  if (id in composition) {
    return composition[id];
  } else if (id in content.data) {
    const data = structuredClone(content.data[id]);
    const action = {
      property: 'data',
      id,
      old: data,
      data,
      updates: {},
      type: '',
      createdAt: Date.now(),
    };
    attachAction(composition, action);
    return action;
  } else {
    const section = content.sections.find((section) => section.id === id);
    if (section) {
      const data = structuredClone(section);
      const action = {
        property: 'sections',
        id,
        data,
        old: data,
        updates: {},
        type: '',
        createdAt: Date.now(),
      };
      attachAction(composition, action);
      return action;
    }
  }
  return null;
};

export const getParentAction = (composition, id) => {
  const child = getAction(composition, id);
  const parentId = child?.data?.parentId;
  const parent = getAction(composition, parentId);
  return parent;
};

export const promoteChildren = (composition, id) => {
  const action = getAction(composition, id);
  const childAction = getAction(composition, action.data.children[0].id);
  if (childAction?.data?.children === 1) {
    return;
  }
  const [firstChild, ...restChildren] = childAction.data.children;
  for (const child of restChildren.reverse()) {
    const childAction = getAction(composition, child.id);
    if (action.data.type === 'row') {
      addBlockToAdjacentRow({
        composition,
        source: childAction,
        destination: action,
        edge: 'bottom',
      });
    }
  }
  updateAction(composition, childAction.id, 'update', { children: [firstChild] });
};

export const removeEmptyActions = (composition) => {
  const emptyList = [];
  const { _content, _options, ...actions } = composition;
  for (const key in actions) {
    const action = composition[key];
    if ('children' in action?.data && action?.data.children.length === 0) {
      updateAction(composition, action.id, 'remove', {});
      emptyList.push(action);
    }
  }

  const clearChildFromParent = (action) => {
    if (action.data.type === 'section') {
      return;
    }
    const parent = getParentAction(composition, action.id);
    const children = parent.data.children.filter((child) => child.id !== action.data.id);
    updateAction(composition, parent.id, 'update', { children });
    if (children.length === 0) {
      clearChildFromParent(parent);

      // TODO: UNCOMMENT CODE BELOW
      //  Check with design team if we should
      //  remove a section if it's empty.

      // if (parent.data.type !== 'section') {
      updateAction(composition, parent.id, 'remove', {});
      // } else {
      //   updateAction(composition, parent.id, 'update', { children });
      // }
    } else if (parent?.data?.type === 'row' && children.length === 1) {
      promoteChildren(composition, parent.id);
    }
  };

  emptyList.forEach(clearChildFromParent);
};

import { attachAction, removeEmptyActions, updateAction } from '../providers/content/composition/action-utils';
import { findAdjacentChildIndex } from '../providers/content/composition/child-utils';
import {
  compileCompositionActions,
  copySectionToComposition,
  getComposition,
  getCopySectionActions,
} from '../providers/content/composition/composition-utils';
import { createSectionAction } from '../providers/content/composition/section-utils';
import { Content } from '../providers/content/Content';
import { removeComposition } from '../providers/content/content-utils';

export const getMultiselect = (selection, strategy, id) => {
  const range = { ...selection?.range };

  if (strategy === 'shift') {
    if (!!range?.anchor) {
      range.focus = id;
    } else {
      range.anchor = id;
      range.focus = id;
    }
  }

  return {
    ...selection,
    at: Date.now(),
    isSelecting: true,
    range,
  };
};

export const getBlock = (content, id) => {
  if (id in content?.data) {
    return content?.data[id];
  } else {
    for (const section of content?.sections) {
      if (section?.id === id) {
        return section;
      }
    }
  }
  return null;
};

const getIndices = (content, block) => {
  let list = [];
  if (block?.parentId) {
    const parent = getBlock(content, block?.parentId);
    const index = parent?.children?.findIndex((child) => child?.id === block?.id);
    const next = getIndices(content, parent);
    list = [...next, index];
    if (parent?.type === 'section') {
      const index = content?.sections?.findIndex((section) => section?.id === parent?.id);
      list = [index, ...list];
    }
  }
  return list;
};

const getRootPath = (content, block) => {
  let list = [];
  if (block?.parentId) {
    const parent = getBlock(content, block?.parentId);
    const index = parent?.children?.findIndex((child) => child?.id === block?.id);
    const next = getRootPath(content, parent);
    list = [...next, parent?.children?.[index]?.id];
    if (parent?.type === 'section') {
      const index = content?.sections?.findIndex((section) => section?.id === parent?.id);
      list = [content?.sections?.[index]?.id, ...list];
    }
  }
  return list;
};

const getChildren = (content, block) => {
  let list = [];
  for (const child of block?.children) {
    const block = getBlock(content, child?.id);
    list.push(block);
    if (block?.children?.length) {
      list = [...list, ...getChildren(content, block)];
    }
  }
  return list;
};

function compareArrays(arr1, arr2) {
  let weightSum1 = 0;
  let weightSum2 = 0;
  for (let i = 0; i < arr1.length; i++) {
    const length = (arr1.length - i) * 100;
    let weight = (1 / (i + 1)) * length; // Higher weight for lower indices
    let val1 = arr1[i]; // Always take from arr1
    let val2 = arr2[i] !== undefined ? arr2[i] : val1; // Use arr2 value if available, else assume it's equal
    weightSum1 += val1 * weight;
    weightSum2 += val2 * weight;
  }
  return weightSum2 >= weightSum1;
}

const getRangeList = (content, first, last) => {
  const firstIndices = getIndices(content, first);
  const lastIndices = getIndices(content, last);
  const firstPath = getRootPath(content, first);
  const lastPath = getRootPath(content, last);
  const firstIndex = content?.sections?.findIndex((section) => section?.id === firstPath[0]);
  const lastIndex = content?.sections?.findIndex((section) => section?.id === lastPath[0]);
  const sections = content?.sections?.slice(firstIndex, lastIndex + 1);

  let list = [];
  for (const section of sections) {
    const children = getChildren(content, section);
    list = [...list, ...children];
  }

  let next = [];

  for (const item of list) {
    const indices = getIndices(content, item);
    const isInFirstRange = compareArrays(firstIndices, indices);
    const isInLastRange = compareArrays(indices, lastIndices);
    if (isInFirstRange && isInLastRange) {
      next.push(item);
    }
  }

  const ids = next.map((block) => block?.id);

  return {
    sections,
    firstPath,
    lastPath,
    list: next,
    ids,
  };
};

export const getRange = (content, range) => {
  const anchor = getBlock(content, range?.anchor);
  const focus = getBlock(content, range?.focus);
  getRangeList(content, anchor, focus);

  const anchorIndices = getIndices(content, anchor);
  const focusIndices = getIndices(content, focus);

  const isAnchorFirst = compareArrays(anchorIndices, focusIndices);
  const first = isAnchorFirst ? anchor : focus;
  const last = isAnchorFirst ? focus : anchor;

  return getRangeList(content, first, last);
};

export const getRemoveMultiSelectionActions = (content, selection) => {
  const composition = getComposition(content);

  let removed = [];
  let parents = [];
  for (const item of selection?.multiSelect?.list) {
    if (item?.type !== 'section' && item?.type !== 'row' && item?.type !== 'column') {
      removeComposition(composition, item?.id);
      removed.push(item?.id);
    } else {
      if (item?.type === 'column') {
        parents.push({ order: 0, item });
      } else if (item?.type === 'row') {
        parents.push({ order: 1, item });
      } else {
        parents.push({ order: 2, item });
      }
    }
  }

  const list = parents.sort((a, b) => a.order - b.order);
  for (const data of list) {
    const { item } = data;
    const block = getBlock(composition?._content, item?.id);
    const blockChildren = block?.children?.filter(({ id }) => !removed.includes(id));
    if (!blockChildren?.length) {
      removed.push(item?.id);
      removeComposition(composition, item?.id);
    }
  }

  removeEmptyActions(composition);
  const actions = compileCompositionActions(composition);
  return actions;
};

export const removeMultiSelection = (setter, selection) => {
  setter((old) => {
    const actions = getRemoveMultiSelectionActions(old, selection);
    const content = Content.commit(old, actions);
    return { content, actions };
  });
};

export const getCopyMultiSelectionActions = (content, selection) => {
  let sections = [];
  let data = {};
  for (const section of selection?.multiSelect?.sections) {
    const children = section.children.filter(({ id }) => selection?.multiSelect?.ids.includes(id));
    sections.push({ ...section, children });
  }
  for (const blockId of selection?.multiSelect?.ids) {
    const block = Content.get(content, blockId);
    if (block?.children) {
      const children = block?.children?.filter(({ id }) => selection?.multiSelect?.ids.includes(id));
      data[blockId] = { ...block, children };
    } else {
      data[blockId] = block;
    }
  }

  const contentToCopy = { sections, data };
  const composition = getComposition(content, { skipCorrections: true });
  const lastSectionId = sections?.at(-1)?.id;
  let index = findAdjacentChildIndex(content?.sections, {
    edge: 'bottom',
    id: lastSectionId,
  });
  for (const section of sections) {
    const sectionAction = createSectionAction(index, []);
    attachAction(composition, sectionAction);
    copySectionToComposition({
      composition,
      contentToCopy,
      sectionFromId: section?.id,
      sectionToId: sectionAction?.id,
    });
    index++;
  }
  removeEmptyActions(composition);
  return compileCompositionActions(composition);
};

import { colors } from '../../shared/styles';
import { Segment } from '../providers/segment/Segment';
import { DROP_CREATE_BLOCK, DROP_MOVE_BLOCK } from '../utility/constants';

const isDraggingStyle = `
  outline-offset: -2px;
  outline: 1px dotted ${colors.gray[300]};
  border-radius: 8px;
  filter: brightness(50%) saturate(0);
`;

const unselectedOrNotDragging = `
  :hover {
    .overlay {
      box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
      outline: 1px solid rgba(255, 255, 255, .5);
      outline-offset: -3px;
      border: 2px solid ${colors.purple}AA;
      background-color: rgba(150, 150, 200, .1);
      margin: -2px;
    }
  }
`;

export const blockContainerDefaultStyle = ({ isHighlighted, isBeingDragged, isDragAvailable }) => `
  position: relative;
  display: flex;
  width: 100%;
  transition: filter .2s ease, padding .2s ease, borderStyle .2s ease;
  box-sizing: border-box;
  ${isBeingDragged ? isDraggingStyle : ''}
  ${isHighlighted || isBeingDragged ? '' : isDragAvailable ? unselectedOrNotDragging : ''}
  z-index: 1000;
  .handle {
    position: absolute;
    top: 0;
    right: 0;
    height: 32px;
    weight: 32px;
    :hover {
      cursor: grab;
    }
  }
  :not(:hover) {
    .handle {
      display: none;
    }
  }
`;

export const blockDefaultStyle = (isSelected) => `
  scroll-margin-top: 10vh;  
  display: flex;
  width: 100%;
  height: 100%;
  overflow: hidden;
  object-fit: none;
  transition: borderStyle .2s ease;
  * {
    box-sizing: border-box;
    ${!isSelected ? `pointer-events: none;` : ''}
  }
  
  transition: background-color .2s ease, padding .2s ease, border-radius .2s ease;
`;

export const DROPPABLE_BLOCK_TYPES = [DROP_CREATE_BLOCK, DROP_MOVE_BLOCK];
const invalidBlocksDrop = ['pageBreak'];

export const getDroppableProps = (segment, drag) => {
  const index = Segment.getIndex(segment, 'block');
  const blockCount = Segment.countParentsChildren(segment);
  const columnCount = Segment.countParentsChildren(segment, 'row');
  const isFirstBlock = index === 0;
  const isLastBlock = index === blockCount - 1;
  const minRange = isFirstBlock ? 10 : 0;
  const maxRange = isLastBlock ? 90 : 100;
  const isDropEnabled =
    !invalidBlocksDrop?.includes(drag?.toolType) &&
    columnCount > 1 &&
    drag?.isDragging &&
    DROPPABLE_BLOCK_TYPES.includes(drag?.type);

  return {
    props: {
      key: 'block',
      activeRects: {
        top: [10, minRange, 90, 50],
        bottom: [10, 50, 90, maxRange],
      },
      disabled: !isDropEnabled,
    },
    isDropEnabled,
    isBeingDragged: drag?.isDragging && drag?.sourceId === segment.id,
  };
};

export const getCanDragBlock = ({ isSelected, segment, drag }) => {
  return drag?.isAvailable && (!isSelected || (segment?.type !== 'textV2' && segment?.type !== 'accordion'));
};

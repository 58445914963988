import { FirstAidKit, PencilSimple } from '@phosphor-icons/react';
import { Box, BriteLoader, Button, Dropdown, Icon, Modal, Text } from '../../../shared/components';
import { container } from '../../../shared/shared-styles';
import { mutliNetworkCategoryMap } from '../../../benefit-package/utils';
import { PRODUCT_HEADERS } from '../../../benefit-package/constants';
import { useToolKit } from '../../providers/tool-kit/use-tool-kit';
import { ToolKit } from '../../providers/tool-kit/ToolKit';
import { useProductSelection } from './useProductSelection';
import { safelySpread } from '../../providers/content/content-modify';
import { useStore } from '../../../store-provider/use-store';
import { SmartFieldInput } from '../../../BriteEditor/utility-components/smart-field-input';
import { ReactComponent as SmartText } from '../../../images/svg/smart-text.svg';
import { history } from '../../../history';
import { useParams } from 'react-router';
import { useSearchParams } from '../../../shared/use-search-params';
import { getProductSmartFields, smartFieldDrillDown } from './smart-component-utils';
import { useState } from 'react';
import { Collapse } from '../../../common/components/Collapse';
import { colors } from '../../../shared/styles';
import { SIDEBAR_TABS } from '../../utility/constants';

const displayMap = { ...PRODUCT_HEADERS, custom: 'Custom' };

export const EditProductSelection = ({ title, blockType, productId, isSmartField, sync, update, onClose }) => {
  const { businessId } = useParams();
  const {
    params: { packageId },
  } = useSearchParams();

  const {
    data: { devMode },
  } = useStore();

  const [loading, setLoading] = useState(false);

  const toolkit = useToolKit();
  const editorType = ToolKit.getEditorType(toolkit);
  const guide = ToolKit.getMetadata(toolkit, 'guide');

  const { product, productsList, selectableProducts, selectableTypes } = useProductSelection(
    blockType,
    sync?.value?.type,
    productId || sync?.value?.data?.selectedId || sync?.value?.data?.id
  );

  const getProductName = (item) => {
    if (item?.ProgramType === 'health_savings_account') {
      const product = productsList?.find(({ ID }) => ID === item?.PlanID);
      return product?.ProductName || displayMap[product?.Type];
    } else {
      return item?.ProductName || displayMap?.[item?.Type];
    }
  };

  const cancel = () => {
    sync?.resync();
    onClose();
  };

  const closeAndOpenConnections = () => {
    ToolKit.setCurrentTab(toolkit, SIDEBAR_TABS.CONNECTIONS);
    ToolKit.emphasizeTab(toolkit);
    cancel();
  };

  const selectProductType = (type) => {
    sync?.merge({
      data: {
        ...safelySpread(sync?.value?.data),
        id: '',
        selectedId: '',
      },
      type,
    });
  };

  const selectProduct = async (product) => {
    if (editorType === 'page') {
      setLoading(true);
      const selectedId = product?.PlanID || product?.ID;
      const smartFields = await getProductSmartFields(guide?.data, product?.Type, selectedId);
      const id = smartFields?.DataSource?.IdentifierTag || selectedId;

      const { relatedProductId, ...rest } = sync?.value?.data;
      let updates = {
        data: {
          ...safelySpread(rest),
          id,
          selectedId,
        },
      };
      if (sync?.value?.type === 'hsa_buyup') {
        const relatedProductIdTag = smartFieldDrillDown(smartFields, [
          'Included Plan Programs',
          'Health Savings Account',
          'Related Product Id',
        ]);
        updates.data.relatedProductId = relatedProductIdTag || product?.RelatedProductID;
      }
      sync?.merge(updates);
      setLoading(false);
    } else {
      sync?.merge({ type: product?.Type });
    }
  };

  const handleSmartField = ({ data }) => {
    sync?.merge({
      data: {
        ...safelySpread(sync?.value?.data),
        id: data?.value,
        smartFieldDisplay: data?.name,
      },
    });
  };
  const removeSmartField = () => {
    const { smartFieldDisplay, ...rest } = sync?.value?.data;
    sync?.merge({
      data: {
        ...safelySpread(rest),
        id: '',
        selectedId: '',
      },
    });
  };

  const routeToProduct = () => {
    const { type } = sync?.value;
    history.push(`/${businessId}/packages/${packageId}/${type}/${productId}`);
  };

  const hasPackage =
    guide?.data?.BenefitsPackageID && guide?.data?.BenefitsPackageID !== '00000000-0000-0000-0000-000000000000';

  const isDisabled = !hasPackage && !guide?.isPublished;

  return (
    <Modal display onClose={cancel} data-scope="modal">
      <Modal.Paper>
        <Modal.Header title={title} onClose={cancel} />
        <Modal.Body
          css={`
            display: grid;
            gap: 16px;
          `}
        >
          <Collapse isOpen={isDisabled}>
            <Box
              flex="left start"
              css={`
                gap: 8px;
                padding: 8px;
                border-radius: 8px;
                ${container.hover}
              `}
              onClick={closeAndOpenConnections}
            >
              <FirstAidKit size={24} color={colors.red[100]} />
              <Text label>
                Connect a <strong>Benefits Package</strong> to select a product
              </Text>
            </Box>
          </Collapse>
          <Box
            css={`
              display: grid;
              gap: 8px;
              ${isDisabled
                ? `
                opacity: .5;
                pointer-events: none;  
              `
                : ''}
            `}
          >
            <Text label bold>
              Select Product Type
            </Text>
            <Dropdown
              clear
              button={(props) => <Dropdown.Select {...props} label={PRODUCT_HEADERS?.[sync?.value?.type]} />}
              popper={{ placement: 'bottom-start' }}
            >
              <Box
                css={`
                  min-width: 300px;
                `}
                data-scope="menu"
              >
                {selectableTypes.map(([type, display]) => (
                  <Box option flex="space-between" onClick={() => selectProductType(type)}>
                    <Text label>{display}</Text>
                  </Box>
                ))}
              </Box>
            </Dropdown>
          </Box>
          {devMode ? (
            <Box>
              <Text label bold>
                Product ID
              </Text>
              <SmartFieldInput
                css={`
                  width: 435px;
                `}
                value={sync?.value?.data?.id}
                smartFieldDisplay={sync?.value?.data?.smartFieldDisplay || ''}
                handleSmartField={handleSmartField}
                removeSmartField={removeSmartField}
                nameFilter="ID"
                inputProps={{
                  value: sync?.value?.data?.id,
                  onChange: (e) => {
                    sync?.merge({
                      data: {
                        ...safelySpread(sync?.value?.data),
                        id: e.target.value,
                      },
                    });
                  },
                }}
              />
            </Box>
          ) : null}
          <Box
            css={`
              display: grid;
              gap: 8px;
              ${!sync?.value?.type || isDisabled
                ? `
                opacity: .5;
                pointer-events: none;  
              `
                : ''}
            `}
          >
            <Box flex="space-between">
              <Text label bold>
                Select Product
              </Text>
              <BriteLoader isLoading={loading} size={24} weight="12px" overlay={false} />
            </Box>
            <Dropdown
              clear
              disabled={!sync?.value?.type}
              popper={{ placement: 'bottom-start' }}
              button={(props) => (
                <Dropdown.Select
                  {...props}
                  label={
                    <Box
                      flex="space-between"
                      css={`
                        padding: 0 8px;
                      `}
                    >
                      <Text label>{loading ? 'Loading...' : getProductName(product)}</Text>
                      {product?.MultiNetworkCategory && (
                        <Text>{mutliNetworkCategoryMap?.[product?.MultiNetworkCategory]}</Text>
                      )}
                    </Box>
                  }
                />
              )}
            >
              <Box
                css={`
                  min-width: 300px;
                `}
                data-scope="menu"
              >
                {selectableProducts.map((product) => (
                  <Box option flex="space-between" onClick={() => selectProduct(product)}>
                    <Text label>{getProductName(product)}</Text>
                    {product?.MultiNetworkCategory && (
                      <Text>{mutliNetworkCategoryMap?.[product?.MultiNetworkCategory]}</Text>
                    )}
                  </Box>
                ))}
              </Box>
            </Dropdown>
          </Box>

          <Collapse isOpen={isSmartField}>
            <Box>
              <Box
                flex="left"
                css={`
                  gap: 8px;
                `}
              >
                <Icon SVG={SmartText} />
                <Text>This field is referencing Smart Text</Text>
              </Box>
            </Box>
          </Collapse>
        </Modal.Body>
        <Modal.Actions>
          <Box
            flex="left"
            css={`
              gap: 8px;
              ${container.hover}
              padding: 8px;
              padding-right: 16px;
              border-radius: 8px;
              margin-right: auto;
              ${!sync?.value?.data?.id
                ? `
                opacity: .5;
                pointer-events: none;
              `
                : ''}
            `}
            onClick={routeToProduct}
          >
            <PencilSimple size={24} />
            <Text label>Edit Product Form</Text>
          </Box>
          <Button secondary onClick={cancel}>
            Cancel
          </Button>
          <Button onClick={update}>Update</Button>
        </Modal.Actions>
      </Modal.Paper>
    </Modal>
  );
};

import { Box } from '../../shared/components';
import { colors } from '../../shared/styles';
import { ToolKit } from '../providers/tool-kit/ToolKit';
import { useToolKit } from '../providers/tool-kit/use-tool-kit';
import { SIDEBAR_TABS } from './constants';
import { overlay } from './styles';

export const ReadOnlyOverlay = () => {
  const toolkit = useToolKit();
  const isLoadingVersions = ToolKit.getIsVersionChangesLoading(toolkit);
  const { type: currentTab } = ToolKit.getCurrentTab(toolkit);

  const handleClick = () => {
    if (currentTab === SIDEBAR_TABS.VERSION_HISTORY) {
      ToolKit.emphasizeTab(toolkit);
    }
  };

  return (
    <Box
      css={`
        ${overlay}
        height: 100%;
        z-index: 1000;
        ${isLoadingVersions
          ? `
          background-color: ${colors.purple}11;
          backdrop-filter: blur(2px);
        `
          : ''}
        transition: background-color 0.4s ease, backdrop-filter 0.4s ease;
        pointer-events: none;
        :active {
          background-color: rgba(255, 255, 255, 0.15);
        }
        :not(:active) {
          .hover {
            display: none;
          }
        }
      `}
      onClick={handleClick}
    />
  );
};

import { css } from '@emotion/css';
import { useQueryAPI } from '../react-query';
import { CustomAxios } from '../redux/axios/axios';
import { Box, Button, ConfirmationModal, Div, Dropdown, Text } from '../shared/components';
import { useStore } from '../store-provider/use-store';
import { useAcls } from '../shared/use-acls';
import { WRITE_BUSINESS_ACCESS } from '../shared/acl-constants';
import { CaretDown, DotsThree, Info } from '@phosphor-icons/react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { AddMember } from '../Dashboard/add-member';
import { EditMember } from './edit-member';
import { container } from '../shared/shared-styles';
import { colors } from '../shared/styles';
import { Tooltip } from '../common/components/Tooltip';
import { Column, Table } from '../shared/components/Table';
import { useSubscription } from '../WebSocket/useSubscription';
import { formatDistanceToNow } from 'date-fns';
import { Circle } from '@phosphor-icons/react';

export const fetchBusinessMembers = async (businessId) => {
  let users = [];
  try {
    const {
      data: { Data: members },
    } = await CustomAxios.get(`v2/business/${businessId}/users?dni_children=true`);
    const filtered = members.filter(({ Roles }) => Roles.some(({ ResourceID }) => ResourceID === businessId));
    const promises = await Promise.allSettled(filtered.map((item) => CustomAxios.get(`v1/users/${item.UserID}`)));
    for (let idx = 0; idx < promises.length; idx++) {
      if (promises[idx].status === 'fulfilled') {
        const member = filtered[idx];
        const user = promises[idx].value.data;
        const role = member.Roles.find(({ ResourceID }) => ResourceID === businessId);
        users.push({ role, ...user });
      }
    }
  } catch (err) {
    console.warn(err);
    return [];
  }
  return users.sort((a, b) => a.Email.localeCompare(b.Email));
};

export const memberRoleMapping = {
  admin: {
    display: 'Admin',
    tooltip: 'No restrictions',
  },
  editor: {
    display: 'Editor',
    tooltip: 'Can edit everything except users',
  },
  guest: {
    display: 'HR Team',
    tooltip: "Can view anything, and can create new guides and edit only those guides they've created",
  },
  viewer: {
    display: 'View Only',
    tooltip: "Can't make edits to anything, view only",
  },
};

const UserPresence = ({ userID }) => {
  const [status, setStatus] = useState('offline');
  const [displayValue, setDisplayValue] = useState(null);

  const onMessage = (message) => {
    switch (message.event.action) {
      case 'user_presence_update': {
        setStatus(message.data.status);
        if (message.data.status !== 'offline') {
          // if the user is Online or Invited we'll just show that
          setDisplayValue(message.data.status);
        } else if (message.data.lastSeen) {
          setDisplayValue(formatDistanceToNow(new Date(message.data.lastSeen), { addSuffix: true }));
        }
        break;
      }
      default:
        break;
    }
  };

  useSubscription({
    onMessage: onMessage,
    keys: [userID],
    type: 'user_presence',
  });

  return (
    <Text label>
      {status === 'online' && (
        <Circle
          className={css`
            margin-right: 4px;
          `}
          size={10}
          weight="fill"
          color={colors.green[200]}
        />
      )}
      <span
        className={css`
          font-size: 0.8em;
          color: ${colors.gray[500]};
          font-style: ${status === 'invited' ? 'italic' : null};
          text-transform: capitalize;
        `}
      >
        {displayValue}
      </span>
    </Text>
  );
};

export const BusinessMembers = () => {
  const {
    data: { selectedBusiness, user, devMode },
  } = useStore();
  const hasWriteAccess = useAcls([WRITE_BUSINESS_ACCESS]);

  const [displayMemberModal, setDisplayMemberModal] = useState(false);
  const [displayEdit, setDisplayEdit] = useState(false);
  const [displayRemoveId, setDisplayRemoveId] = useState('');

  const { data, cache, refetch, isLoading } = useQueryAPI({
    urlKey: `business-members/${selectedBusiness.ID}`,
    queryFn: () => fetchBusinessMembers(selectedBusiness.ID),
    enabled: !!selectedBusiness.ID,
    defaultValue: [],
  });

  const handlePermission = async (roleId, member) => {
    const userId = member.ID;
    const businessId = selectedBusiness.ID;

    const old = [...data];
    const updates = data.map((item) =>
      item.ID === userId ? { ...item, role: { ...item.role, RoleID: roleId } } : item
    );

    try {
      cache.setData(updates);
      await CustomAxios.delete(`v1/user/${userId}/access/${businessId}`);
      await CustomAxios.post(`v1/user/${userId}/access/${businessId}/role/${roleId}`);
      window?.ChurnZero?.push(['trackEvent', 'Settings', `Permissions updated`, 1]);
    } catch (err) {
      toast.error('Failed to update the users permission');
      cache.setData(old);
      console.warn(err);
    } finally {
      refetch();
    }
  };

  const removeAccess = async () => {
    const userId = displayRemoveId;
    const businessId = selectedBusiness.ID;
    const old = [...data];
    const updates = data.filter((item) => item.ID !== userId);
    setDisplayRemoveId('');
    try {
      cache.setData(updates);
      await CustomAxios.delete(`v1/user/${userId}/access/${businessId}`);
    } catch (err) {
      cache.setData(old);
      toast.error('Failed to remove users access.');
      console.warn(err);
    } finally {
      refetch();
    }
  };

  const handleCloseMemberModal = () => {
    refetch();
    setDisplayMemberModal(false);
  };

  return (
    <Div>
      <Div
        css={css`
          ${container.header}
        `}
      >
        <Text styles="h1">Members</Text>
        <Div
          css={css`
            display: flex;
          `}
        >
          {!hasWriteAccess ? (
            <Button disabled="true" hoverLabel="Must be an admin to invite members">
              <Text bold>Invite Member</Text>
            </Button>
          ) : (
            <Button onClick={() => setDisplayMemberModal(true)}>Invite Member</Button>
          )}
        </Div>
      </Div>
      <Table list={data} isLoading={isLoading} emptyMessage="No members found.">
        <Column
          columnHeader="Name"
          css={`
            width: 30%;
          `}
        >
          {(data) => (
            <Box flex="left" key={data?.ID}>
              <Text label>
                {' '}
                {data.FirstName} {data.LastName}
              </Text>
            </Box>
          )}
        </Column>
        <Column
          columnHeader="Email"
          css={`
            width: 30%;
          `}
        >
          {(data) => (
            <Box flex="left">
              <Text label> {data.Email}</Text>
            </Box>
          )}
        </Column>
        {devMode && (
          <Column
            columnHeader="Last Seen"
            css={`
              width: 15%;
            `}
          >
            {(data) => (
              <Box flex="left" key={data?.ID}>
                <UserPresence userID={data.ID}></UserPresence>
              </Box>
            )}
          </Column>
        )}
        <Column
          columnHeader="Permissions"
          css={`
            width: 30%;
          `}
        >
          {(data) => (
            <Box flex="left" key={data?.ID}>
              <Dropdown
                css={`
                  width: 100%;
                  max-width: 200px;
                  background-color: none;
                  padding: 0;
                `}
                popper={{ placement: 'bottom-end' }}
                button={() => (
                  <Box
                    flex="space-between"
                    css={`
                      border-radius: 8px;
                      border: 1px solid ${colors.gray[300]};
                      padding: 8px;
                      width: 100%;
                    `}
                  >
                    <Text label>{memberRoleMapping[data?.role?.RoleID]?.display}</Text>
                    <CaretDown size={24} />
                  </Box>
                )}
              >
                {Object.entries(memberRoleMapping)?.map(([key, role]) => (
                  <Box
                    option
                    value={key}
                    item={data}
                    onClick={() => handlePermission(key, data)}
                    style={{
                      width: '150px',
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                    }}
                  >
                    <Text label>{role?.display}</Text>
                    <Tooltip
                      className={css`
                        z-index: 10000;
                      `}
                      label={role?.tooltip}
                      popper={{ placement: 'right' }}
                    >
                      <Info size={24} />
                    </Tooltip>
                  </Box>
                ))}
              </Dropdown>
            </Box>
          )}
        </Column>
        <Column
          columnHeader="Options"
          headerCss={`text-align: right; padding-right: 16px;`}
          css={`
            width: 64px;
          `}
        >
          {(data) => (
            <Box flex="right" key={data?.ID}>
              <Dropdown
                popper={{ placement: 'bottom-end' }}
                button={() => (
                  <Button styles="icon" disabled={!hasWriteAccess}>
                    <DotsThree />
                  </Button>
                )}
              >
                {data.ID === user.ID && (
                  <Box option onClick={() => setDisplayEdit(true)}>
                    Edit
                  </Box>
                )}

                {
                  // can't remove yourself if you're the last member
                  (data.ID !== user.ID || data?.length > 1) && (
                    <Box option onClick={() => setDisplayRemoveId(data.ID)}>
                      Remove Access
                    </Box>
                  )
                }
              </Dropdown>
            </Box>
          )}
        </Column>
      </Table>

      <AddMember
        display={displayMemberModal}
        onClose={handleCloseMemberModal}
        defaultBusiness={selectedBusiness}
        hideBusinessSelection={true}
      />

      <EditMember
        display={displayEdit}
        onClose={() => {
          setDisplayEdit(false);
          refetch();
        }}
      />

      <ConfirmationModal
        title="Are you sure you want to remove access for this user?"
        display={!!displayRemoveId}
        onClose={() => setDisplayRemoveId('')}
        onConfirm={removeAccess}
        isDeleteAlert
        confirmText="Remove Access"
      />
    </Div>
  );
};
